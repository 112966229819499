import { type Video } from '@/stores/types';
import { REQUEST_STATUS, type State } from '@/types';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from '@/stores/index';

export interface SelectedEvent {
  eventId: string;
  selectedVideo: Video[];
}

export interface SelectedEventState extends State<SelectedEvent> {}

const initialState: SelectedEventState = {
  data: { eventId: '', selectedVideo: [] },
  requestStatus: REQUEST_STATUS.IDLE,
  error: null,
};

export const selectedVideoSlice = createSlice({
  name: 'selectedEvent',
  initialState,
  reducers: {
    updateSelectedVideo: (state, { payload }: PayloadAction<{ eventId: string; video: Video }>) => {
      const { eventId, video } = payload;
      if (state.data.eventId === eventId) {
        if (state.data.selectedVideo.some((selectedVideo) => selectedVideo.bucketObjectId === video.bucketObjectId)) {
          state.data.selectedVideo = state.data.selectedVideo.filter((selectedVideo) => selectedVideo.bucketObjectId !== video.bucketObjectId);
        } else {
          state.data.selectedVideo.push(video);
        }
      } else {
        window.logger.warn(`Selected video does not match the current siteId and eventId.`);
      }
    },
    setSelectedEvent: (state, { payload }: PayloadAction<{ eventId: string }>) => {
      const { eventId } = payload;
      state.data = { eventId, selectedVideo: [] };
    },
  },
});

export default selectedVideoSlice.reducer;

export const { updateSelectedVideo, setSelectedEvent } = selectedVideoSlice.actions;

export const selectSelectedVideo = (state: RootState): Video[] => {
  return state.selectedEvent.data.selectedVideo;
};
