import { $ } from '@/common';

interface HeaderProps {
  children: JSX.Element | JSX.Element[];
}

const Header = ({ children }: HeaderProps): JSX.Element => {
  return <div className={$('flex h-16 items-center px-6 bg-white shadow sticky top-0 z-10')}>{children}</div>;
};

export const Title = ({ children }: { children: JSX.Element | string }): JSX.Element => {
  return <span className={$('text-2xl font-light text-emphasis-secondary')}>{children}</span>;
};

export default Header;
