import React from 'react';
import classNames from 'classnames';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { type Ref } from 'react';
import { SearchIcon } from '@/common/assets/icons';

interface EventTagSearchBarProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string | JSX.Element;
  options: string[];
}

const _filterOptions = createFilterOptions<string>();
const filterOption = (props: any, state: any): string[] => {
  const results = _filterOptions(props, state);
  return results.slice(0, 6);
};

interface OptionItemProps {
  option: string;
}

function OptionItem({ option, ...other }: OptionItemProps): JSX.Element {
  return (
    <li
      {...other}
      style={{
        display: 'block',
      }}
    >
      <div>
        <Chip label={option} />
      </div>
    </li>
  );
}

const EventTagSearchBar = (props: EventTagSearchBarProps, ref: Ref<HTMLInputElement>): JSX.Element => {
  const { options, onChange } = props;

  return (
    <div className={classNames('text-emphasis-light font-normal text-base')}>
      <Autocomplete
        multiple
        options={options}
        fullWidth
        onChange={(e, data) => {
          // @ts-expect-error inference of type of data here is incorrect
          onChange?.(data);
        }}
        forcePopupIcon={false}
        ListboxProps={{
          style: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: 3,
            maxHeight: 'initial',
          },
        }}
        disableCloseOnSelect
        filterOptions={filterOption}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option === value}
        getOptionLabel={(option) => option}
        renderOption={(props, option, { selected }) => <OptionItem {...props} option={option} />}
        renderInput={(params) => (
          <TextField
            {...params}
            size='medium'
            placeholder={'Tags'}
            inputRef={ref}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default React.forwardRef(EventTagSearchBar);
