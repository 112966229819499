import { Outlet, useLocation } from 'react-router-dom';
import Header, { Title } from './components/header';
import { Navigation, NavigationItem } from './components/navigation';
import { useAppSelector } from '@/common';
import { selectSiteConfig } from '@/stores/siteConfigs';
import { useJitsu } from '@jitsu/jitsu-react';
import { useEffect } from 'react';

const DesktopMasterLayout = (): JSX.Element => {
  const siteConfig = useAppSelector(selectSiteConfig);

  const { analytics } = useJitsu();
  const location = useLocation();

  // Create Jitsu 'page' event when user navigates to a new page
  useEffect(() => {
    analytics.page();
  }, [location]);

  return (
    <>
      <Header>
        <Title>{siteConfig?.details.name ?? ''}</Title>
        <Navigation>
          <NavigationItem label='Events' name='monitor-events' to='events' />
          <NavigationItem label='Saved Events' name='monitor-archive' to='archive' />
        </Navigation>
      </Header>
      <div className='py-5 px-8'>
        <Outlet />
      </div>
    </>
  );
};

export default DesktopMasterLayout;
