import { type AnyAction, type CombinedState, combineReducers, configureStore, type PreloadedState } from '@reduxjs/toolkit';
import eventsReducer, { type EventState } from './events';
import siteConfigsReducer, { type SiteConfigState } from './siteConfigs';
import selectSelectedEventReducer, { type SelectedEventState } from './selectedEvent';
import eventMetadataReducer, { type EventMetadataState } from './eventMetadata';

interface CombinedStateType {
  siteConfigs: SiteConfigState;
  events: EventState;
  selectedEvent: SelectedEventState;
  eventMetadata: EventMetadataState;
}

const appReducer = combineReducers({
  siteConfigs: siteConfigsReducer,
  events: eventsReducer,
  selectedEvent: selectSelectedEventReducer,
  eventMetadata: eventMetadataReducer,
});

const rootReducer = (state: CombinedState<CombinedStateType> | undefined, action: AnyAction): CombinedState<CombinedStateType> => {
  if (action.type === 'RESET_STATE') {
    state = action.payload;
  }

  return appReducer(state, action);
};

export const setupStore = (preloadedState?: PreloadedState<RootState>): any => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export const store = setupStore();
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type Selector<S> = (state: RootState) => S;
