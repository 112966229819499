import { REQUEST_STATUS, type State } from '@/types';
import { type EventMetadata } from '@/stores/types';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { type RootState } from '@/stores/index';
import createHttp from '@/services/http';
import get from 'lodash/get';

export interface EventMetadataState extends State<EventMetadata | undefined> {}

const initialState: EventMetadataState = {
  data: undefined,
  requestStatus: REQUEST_STATUS.IDLE,
  error: null,
};

export const getEventMetadata = createAsyncThunk<EventMetadata | undefined, { siteId: string }>(
  'eventMetadata/getEventMetadata',
  async ({ siteId }): Promise<EventMetadata | undefined> => {
    const response: any = await createHttp(process.env.REACT_APP_CATCH_API_URL).get(`/eventmetadata/${siteId}`);

    const data: { item: EventMetadata } = get(response, 'data', null);

    // There should be only item in the array per site id
    if (data === null) {
      console.warn('No data in reponse');
      return undefined;
    }

    return data.item;
  },
);

export const eventMetadataSlice = createSlice({
  name: 'eventMetadata',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEventMetadata.pending, (state) => {
        state.requestStatus = REQUEST_STATUS.LOADING;
      })
      .addCase(getEventMetadata.fulfilled, (state, action) => {
        state.requestStatus = REQUEST_STATUS.SUCCEEDED;
        if (action.payload !== undefined) {
          state.data = action.payload;
        }
      })
      .addCase(getEventMetadata.rejected, (state, action) => {
        state.requestStatus = REQUEST_STATUS.FAILED;
        state.error = action.error;
      });
  },
});

export default eventMetadataSlice.reducer;

export const selectEventMetadata = (state: RootState): EventMetadata | undefined => state.eventMetadata.data;

export const selectEventTypes = createSelector([selectEventMetadata], (eventMetadata) => (eventMetadata !== undefined ? eventMetadata?.eventTypes : []));

export const selectEventTags = createSelector([selectEventMetadata], (eventMetadata) => (eventMetadata !== undefined ? eventMetadata?.eventTags : []));
