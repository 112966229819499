import { REQUEST_STATUS, type State } from '@/types';
import { type Tag } from '@/stores/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { type RootState } from '@/stores/index';
import createHttp from '@/services/http';
import get from 'lodash/get';

export interface TagState extends State<Tag[]> {}

const initialState: TagState = {
  data: [] as Tag[],
  requestStatus: REQUEST_STATUS.IDLE,
  error: null,
};

export const getTags = createAsyncThunk<Tag[], { siteId: string }>('tags/getTags', async ({ siteId }): Promise<Tag[]> => {
  const response: any = await createHttp(process.env.REACT_APP_CATCH_API_URL).get(`/tags/${siteId}`);
  const data: { items: Array<{ siteId: string; tags: Tag[] }> } = get(response, 'data', null);

  // There should be only item in the array per site id
  if (data === null || data.items.length === 0 || data.items[0].tags.length === 0) {
    return [];
  }

  return data.items[0].tags;
});

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTags.pending, (state) => {
        state.requestStatus = REQUEST_STATUS.LOADING;
      })
      .addCase(getTags.fulfilled, (state, action) => {
        state.requestStatus = REQUEST_STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(getTags.rejected, (state, action) => {
        state.requestStatus = REQUEST_STATUS.FAILED;
        state.error = action.error;
      });
  },
});

export default tagsSlice.reducer;

export const selectTags = (state: RootState): Tag[] => state.tags.data;
