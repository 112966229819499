import { type SerializedError } from '@reduxjs/toolkit';

export enum REQUEST_STATUS {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export interface State<T> {
  data: T;
  requestStatus: REQUEST_STATUS;
  error: SerializedError | null;
}

export enum FeatureFlags {
  // Add feature flags go here, along with explanation of their purpose and if/when they can be cleaned up

  // This flag was added 2024-11-19 to enable filtering on monitor events
  // This flag can be removed once ability to filter on event tags has been implemented
  eyecueMonitorUseEventFilters = 'eyecueMonitorUseEventFilters',
}
