import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { PauseIcon, PlayIcon } from '@/common/assets/icons';
import { $ } from '@/common';
import Video from '@/modules/clips/components/playback/Video';

interface PlaybackProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: JSX.Element | JSX.Element[];
  disablePlaytrack?: boolean;
}

enum PlaybackState {
  PLAYING,
  PAUSED,
  ENDED,
}

// set state isn't synchronous, so we need to keep track of the last progress to avoid setting
let lastProgress = 0;

const Playback = (props: PlaybackProps): JSX.Element => {
  const { children, disablePlaytrack = false, ...rest } = props;
  const [videoElements, setVideoElements] = useState<HTMLVideoElement[]>([]);
  const [playbackState, setPlaybackState] = useState<PlaybackState>(PlaybackState.ENDED);
  const [currentProgress, setCurrentProgress] = useState(0);

  const items = useMemo(() => {
    if (children === undefined) {
      return [];
    }

    if (Array.isArray(children)) {
      return children;
    }

    return [children];
  }, [children]);

  const longestVideo = useMemo(() => {
    return videoElements.reduce((longest: HTMLVideoElement | null, video: HTMLVideoElement) => {
      if (longest === null) {
        return video;
      }

      return video.duration > longest.duration ? video : longest;
    }, null);
  }, [videoElements]);

  const updateProgress = useCallback((progress: number) => {
    lastProgress = progress;
    setCurrentProgress(progress);
  }, []);

  const playAll = useCallback(() => {
    if (playbackState === PlaybackState.ENDED) {
      updateProgress(0);
    }

    setPlaybackState(PlaybackState.PLAYING);
    videoElements.forEach((video) => video.play());
  }, [videoElements, playbackState]);

  const pauseAll = useCallback(() => {
    setPlaybackState(PlaybackState.PAUSED);
    videoElements.forEach((video) => video.pause());
  }, [videoElements]);

  const onVideoLoaded = useCallback((event: SyntheticEvent) => {
    const video = event.target as HTMLVideoElement;

    setVideoElements((prevState) => {
      return [...prevState, video];
    });
  }, []);

  const onEnded = useCallback(() => {
    setPlaybackState(PlaybackState.ENDED);
  }, []);

  // Only update the progress if the video is the longest video
  const onTimeUpdate = useCallback(
    (event: SyntheticEvent) => {
      const video = event.target as HTMLVideoElement;

      if (longestVideo === null || video.src !== longestVideo.src) {
        return;
      }

      const progress = (video.currentTime / video.duration) * 100;
      if (progress > lastProgress) {
        updateProgress(progress);
      }
    },
    [longestVideo, updateProgress],
  );

   // Function to handle the progress bar drag/change
   const onProgressChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newProgress = Number(event.target.value);
      updateProgress(newProgress);

      if (longestVideo) {
        const newTime = (newProgress / 100) * longestVideo.duration;

        // Seek all videos to the calculated time
        videoElements.forEach((video) => {
          video.currentTime = newTime;
        });
      }
    },
    [longestVideo, updateProgress, videoElements],
  );

  return (
    <div>
      <div {...rest}>
        {items.map((item, index) => {
          const { src, ...rest } = item.props;
          return <Video key={index} src={src} {...rest} onLoadedMetadata={onVideoLoaded} onTimeUpdate={onTimeUpdate} onEnded={onEnded} />;
        })}
      </div>
      <div className={$('flex items-center justify-center pt-4', { hidden: disablePlaytrack })}>
        <button type='button' className={$('border-0')} onClick={playbackState === PlaybackState.PLAYING ? pauseAll : playAll}>
          {playbackState === PlaybackState.PLAYING ? (
            <PauseIcon className={$('w-6 h-6 fill-secondary-green')} />
          ) : (
            <PlayIcon className={$('w-6 h-6 fill-secondary-green')} />
          )}
        </button>
        <input
          id='default-range'
          type='range'
          min='0'
          max='100'
          value={currentProgress}
          onChange={onProgressChange} 
          className={$(
            'w-full h-[14px] border border-secondary-green bg-white rounded-lg range-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:bg-button-primary [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-6  [&::-webkit-slider-thumb]:h-6 [&::-webkit-slider-thumb]:rounded-full',
          )}
        />
      </div>
    </div>
  );
};

export default Playback;
