import { $ } from '@/common';

interface HeaderProps {
  children: JSX.Element | JSX.Element[];
}

const Header = ({ children }: HeaderProps): JSX.Element => {
  return (
    <div className={$('mt-40')}>
      <div className={$('fixed top-20 left-20 5xl:left-72 right-0 bg-white shadow z-30')}>
        <div className={$('flex items-center px-6 h-16')}>{children}</div>
      </div>
    </div>
  );
};

export const Title = ({ children }: { children: JSX.Element | string }): JSX.Element => {
  return <span className={$('text-2xl font-light text-emphasis-secondary')}>{children}</span>;
};

export default Header;
