import createHttp from '@/services/http';
import { REQUEST_STATUS, type State } from '@/types';
import { type ActionReducerMapBuilder, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { type RootState, type Selector } from '.';
import { isEmpty } from '@/common';
import { EventType, type Event, type TagClipFormModel } from '@/stores/types';
import { type EventFilter } from '@/modules/events/components/eventFilter/EventFilterForm';

export interface EventState extends State<Record<EventType, { events: Event[]; nextToken?: string; lastViewedEvent?: Event }>> {}

interface NextEvents {
  events: Event[];
  nextToken: string;
  eventType: EventType;
}

interface FilteredEvents {
  events: Event[];
}

const initialState: EventState = {
  data: {
    [EventType.REVIEW]: { events: [], nextToken: undefined },
    [EventType.ARCHIVE]: { events: [], nextToken: undefined },
  },
  requestStatus: REQUEST_STATUS.IDLE,
  error: null,
};

export const getSingleEvent = createAsyncThunk<{ event: Event }, { siteId: string; eventId: string }>('events/getSingleEvent', async ({ siteId, eventId }) => {
  const response: any = await createHttp(process.env.REACT_APP_CATCH_API_URL).get(`/events/${siteId}/${eventId}`);
  const data = get(response, 'data', null);

  return { event: data };
});

export const getNextEvents = createAsyncThunk<NextEvents, { siteId: string; eventType: EventType }>(
  'events/getNextEvents',
  async ({ siteId, eventType }, { getState }) => {
    const nextToken = (getState() as RootState).events.data[eventType].nextToken ?? '';
    const response: any = await createHttp(process.env.REACT_APP_CATCH_API_URL).get(
      `/events/${siteId}?limit=25&reviewed=${eventType === EventType.ARCHIVE}&nextToken=${nextToken}`,
    );
    const data = get(response, 'data', null);

    return { events: data.items, nextToken: data.nextToken, eventType };
  },
);

export const getFilteredEvents = createAsyncThunk<FilteredEvents, { siteId: string; eventType: EventType; eventFilter: EventFilter }>(
  'events/getFilteredEvents',
  async ({ siteId, eventType, eventFilter }) => {
    const params = new URLSearchParams();

    // Required parameters
    params.set('limit', '25');
    params.set('reviewed', eventType === EventType.ARCHIVE ? 'true' : 'false');

    // Optional params
    const optionalParams: Record<string, string | string[] | undefined> = {
      thresholdFrom: eventFilter.startDateTime !== null ? eventFilter.startDateTime?.toISOString() : undefined,
      thresholdTo: eventFilter.endDateTime !== null ? eventFilter.endDateTime?.toISOString() : undefined,
      type: eventFilter.eventTypeFilter !== undefined && eventFilter.eventTypeFilter !== '' ? eventFilter.eventTypeFilter : undefined,
      tags: eventFilter.eventTagsFilter !== undefined && eventFilter.eventTagsFilter.length > 0 ? eventFilter.eventTagsFilter : undefined,
    };

    // Add optional parameters
    Object.entries(optionalParams).forEach(([key, value]) => {
      if (value !== undefined) {
        if (Array.isArray(value)) {
          // For array values, append multiple times
          value.forEach((item) => {
            params.append(key + '[]', item);
          });
        } else {
          params.set(key, value);
        }
      }
    });

    const response: any = await createHttp(process.env.REACT_APP_CATCH_API_URL).get(`/events/${siteId}?${params.toString()}`);

    const data = get(response, 'data', null);

    return { events: data.items };
  },
);

export const tagAndArchiveEvent = createAsyncThunk<Event, { payload: Partial<TagClipFormModel> }>('events/tagAndArchiveEvent', async ({ payload }) => {
  const { siteId, eventId, tagNames, archivedVideos, userNotes } = payload;

  const tags = tagNames === undefined ? undefined : tagNames.map((tagName) => ({ tagName }));

  const response = await createHttp(process.env.REACT_APP_CATCH_API_URL).put(`/events/${siteId}/${eventId}`, {
    archivedVideos,
    reviewed: true,
    userNotes,
    tags,
  });

  return get(response, 'data', null);
});

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<EventState>) => {
    builder
      .addCase(getNextEvents.pending, (state) => {
        state.requestStatus = REQUEST_STATUS.LOADING;
      })
      .addCase(getNextEvents.fulfilled, (state, action) => {
        state.requestStatus = REQUEST_STATUS.SUCCEEDED;
        const { events, nextToken, eventType } = action.payload;

        if (isEmpty(state.data[eventType].nextToken)) {
          // Refreshing the list when there is no nextToken
          state.data[eventType].events = events;
        } else {
          state.data[eventType].events.push(...events);
        }

        state.data[eventType].nextToken = nextToken;
      })
      .addCase(getNextEvents.rejected, (state, action) => {
        state.requestStatus = REQUEST_STATUS.FAILED;
        state.error = action.error;
      });

    builder.addCase(getSingleEvent.fulfilled, (state, action) => {
      state.requestStatus = REQUEST_STATUS.SUCCEEDED;

      const { event } = action.payload;

      const eventType = event.reviewed ? EventType.ARCHIVE : EventType.REVIEW;

      state.data[eventType].events = [event];
    });

    builder
      .addCase(tagAndArchiveEvent.fulfilled, (state, action) => {
        // Response from tagAndArchiveEvent is always archived event (archive === true)
        const archivedEvent = action.payload;

        // Remove the event from "REVIEW" list (if exists)
        const reviewEvents = state.data[EventType.REVIEW].events;
        const reviewIndex = reviewEvents.findIndex((event) => event.eventId === archivedEvent.eventId);
        if (reviewIndex !== -1) {
          reviewEvents.splice(reviewIndex, 1);
        }

        // Add/update the event to "ARCHIVE" list
        const archiveEvents = state.data[EventType.ARCHIVE].events;
        const archiveIndex = archiveEvents.findIndex((event) => event.eventId === archivedEvent.eventId);
        if (archiveIndex === -1) {
          archiveEvents.push(archivedEvent);
        } else {
          archiveEvents[archiveIndex] = archivedEvent;
        }
      })
      .addCase(tagAndArchiveEvent.rejected, (state, action) => {
        state.requestStatus = REQUEST_STATUS.FAILED;
        state.error = action.error;
      });
    builder
      .addCase(getFilteredEvents.fulfilled, (state, action) => {
        const { events } = action.payload;
        const eventType = action.meta.arg.eventType;

        state.data[eventType].events = events;
        state.requestStatus = REQUEST_STATUS.SUCCEEDED;
      })
      .addCase(getFilteredEvents.pending, (state, action) => {
        const eventType = action.meta.arg.eventType;

        state.data[eventType].events = [];
        state.requestStatus = REQUEST_STATUS.LOADING;
      });
  },
});

export default eventsSlice.reducer;

const selectAllEvents = (state: RootState): typeof initialState.data => state.events.data;

export const selectEvents = (eventType: EventType): Selector<Event[]> => createSelector([selectAllEvents], (allEvents): Event[] => allEvents[eventType].events);

export const selectIsEventsLoading = (state: RootState): boolean => state.events.requestStatus === REQUEST_STATUS.LOADING;

export const selectHasMoreEvents = (eventType: EventType): Selector<boolean> =>
  createSelector([selectAllEvents], (allEvents): boolean => !isEmpty(allEvents[eventType].nextToken));

export const selectEvent = (eventId?: string): Selector<Event | undefined> =>
  createSelector([selectAllEvents], (allEvents): Event | undefined =>
    [...allEvents[EventType.ARCHIVE].events, ...allEvents[EventType.REVIEW].events].find((event) => event.eventId === eventId),
  );
