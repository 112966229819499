import { store } from '@/stores';
import App from '@/App';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty, parseSiteId } from '@/common';
import { getSiteConfig } from '@/stores/siteConfigs';

const Bootstrap = (): JSX.Element => {
  const { siteId } = useParams();

  useEffect(() => {
    window.logger.info(`Bootstrap ${siteId}`);

    if (isEmpty(siteId)) {
      window.logger.error('Invalid siteId');
      return;
    }

    const { clientId } = parseSiteId(siteId!);

    window.logger.info(`getSiteConfig ${siteId}`);

    store.dispatch(
      getSiteConfig({
        siteId: siteId!,
        clientId,
      }),
    );

    return () => {
      window.logger.info(`State cleanup ${siteId}...`);
      store.dispatch({ type: 'RESET_STATE', payload: undefined });
    };
  }, []);

  return <App />;
};

export default Bootstrap;
