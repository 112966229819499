import { type SerializedError } from '@reduxjs/toolkit';

export enum REQUEST_STATUS {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export interface State<T> {
  data: T;
  requestStatus: REQUEST_STATUS;
  error: SerializedError | null;
}
