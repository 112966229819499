import React, { type Ref } from 'react';

interface EventTypeSelectorProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label: string | JSX.Element;
  options: Array<{ value: string; label: string }>;
}

const EventTypeSelector = (props: EventTypeSelectorProps, ref: Ref<HTMLSelectElement>): JSX.Element => {
  const { label, name, options, value, onChange, ...rest } = props;

  return (
    <div>
      <select
        ref={ref}
        name={name}
        value={value ?? ''}
        onChange={onChange}
        {...rest}
        style={{
          width: '100%',
          padding: '8px',
          borderRadius: '4px',
          border: '1px solid #ccc',
        }}
      >
        <option value=''>{label}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default React.forwardRef(EventTypeSelector);
