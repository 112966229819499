import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { $, useAppSelector } from '@/common';
import React, { type Ref, useMemo } from 'react';
import Badge from '@/modules/clips/components/badge';
import classNames from 'classnames';
import { styled } from '@mui/material/styles';
import { selectEventTags } from '@/stores/eventMetadata';

const StyledTextField = styled(TextField)({
  "& [type='text']:focus": {
    '--tw-ring-color': 'transparent',
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-inputRoot': {
    borderRadius: '0.375rem',
    borderColor: '#A7ACAF',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#A7ACAF',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#00B780',
    },
  },
});

const filter = createFilterOptions<TagOptionType>();

interface TagSelectProps {
  value: string[];
  onChange: (event: any) => void;
}

interface TagOptionType {
  value: string;
  label: string;
}

const TagSelect = (props: TagSelectProps, ref: Ref<HTMLElement>): JSX.Element => {
  const { onChange, value } = props;
  const availableTags = useAppSelector(selectEventTags);
  const suggestedTags = useMemo(() => [...availableTags].sort((a, b) => (b.count ?? 0) - (a.count ?? 0)).slice(0, 8), [availableTags]);
  const availableOptions = useMemo(() => availableTags.map((tag): TagOptionType => ({ value: tag.tagName, label: tag.tagName })), [availableTags]);
  const tags = useMemo(() => value.map((tag): TagOptionType => ({ value: tag, label: tag })), [value]);

  return (
    <div>
      <span className={$('font-semibold text-base text-emphasis-secondary')}>Suggested tags</span>
      <div className={$('mt-4 mb-4 flex flex-wrap gap-x-2 gap-y-2')}>
        {suggestedTags.length > 0 ? (
          suggestedTags.map((tag, index) => (
            <SuggestedTag
              key={`tag-${index}`}
              value={tag.tagName}
              checked={value.includes(tag.tagName)}
              onChange={(e) => {
                const newValue = e.target.checked ? [...value, tag.tagName] : value.filter((v) => v !== tag.tagName);
                onChange(newValue);
              }}
            />
          ))
        ) : (
          <div className={$('grid min-h-full place-items-center text-emphasis-secondary')}>
            <span>
              Your site has no tagged events yet.
              <br /> Add tags to this event using the text field below, and your tags will be suggested for future events.
            </span>
          </div>
        )}
      </div>
      <StyledAutocomplete
        ref={ref}
        multiple
        size={'small'}
        filterSelectedOptions
        autoHighlight
        freeSolo
        onChange={(_, value) => {
          onChange((value as TagOptionType[]).map((v) => (typeof v === 'string' ? v : v.value)));
        }}
        value={tags}
        options={availableOptions}
        getOptionLabel={(option) => (typeof option === 'string' ? option : (option as TagOptionType).label)}
        filterOptions={(options, params) => {
          const filtered = filter(options as TagOptionType[], params);
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === (option as TagOptionType).value);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              value: inputValue,
              label: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        isOptionEqualToValue={(option, value) => (option as TagOptionType).value === (value as TagOptionType).value}
        renderInput={(params) => <StyledTextField {...params} />}
      />
    </div>
  );
};

interface SuggestedTagProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const SuggestedTag = (props: SuggestedTagProps): JSX.Element => {
  const { onChange, checked, ...rest } = props;
  const inputElementRef = React.useRef<HTMLInputElement | null>(null);

  return (
    <Badge
      className={classNames('cursor-pointer min-w-20', { 'bg-emphasis-primary text-emphasis-overlay ring-transparent': checked })}
      onClick={() => {
        if (inputElementRef.current !== null) {
          inputElementRef.current.click();
        }
      }}
    >
      <span>{props.value}</span>
      <input ref={inputElementRef} hidden type='checkbox' onChange={onChange} checked={checked} {...rest} />
    </Badge>
  );
};

export default React.forwardRef(TagSelect);
