import { $ } from '@/common';
import { SpinnerIcon } from '@/common/assets/icons';

interface SpinnerProps {
  message?: string;
  hide?: boolean;
}

const Spinner = (props: SpinnerProps): JSX.Element => {
  const { message = 'Loading...', hide = false } = props;

  return !hide ? (
    <div className={$('relative pt-16')} role='status'>
      <div className={$('absolute w-full items-center justify-center flex inset-y-0 left-0 z-10')}>
        <SpinnerIcon />
        <span className={$('sr-only')}>{message ?? 'Loading...'}</span>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Spinner;
