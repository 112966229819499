import Bootstrap from '@/bootstrap';
import { Navigate, Outlet, Route, createBrowserRouter, createRoutesFromElements, RouterProvider, type LoaderFunctionArgs } from 'react-router-dom';
import { getNextEvents } from '@/stores/events';
import { useAppDispatch } from '@/common';
import { EventType } from '@/stores/types';
import { ActionCreatorWithPayload, type AsyncThunk } from '@reduxjs/toolkit';
import { setSelectedEvent } from '@/stores/selectedEvent';
import Review from '@/modules/events/review';
import Archive from '@/modules/events/archive';
import SelectedClips from '@/modules/clips/selectedClips';
import Clips from '@/modules/clips';
import TagClips from '@/modules/clips/tagClips';
import { getContentAccess } from '@/stores/siteConfigs';
import { ResolveEvent } from './resolvers/ResolveEvent';
import { store } from '@/stores';
import { getEventMetadata } from '@/stores/eventMetadata';

const AppRouter = (): JSX.Element => {
  const dispatch = useAppDispatch();
  // Generic function to dispatch actions with path params
  const dispatchActions =
    (...actions: Array<AsyncThunk<any, any, any> | ActionCreatorWithPayload<any>>) =>
    async ({ params }: LoaderFunctionArgs<any>): Promise<number> => {
      actions.forEach((action) => {
        dispatch(action({ ...params }));
      });

      return 0;
    };

  const getContentTokenLoader = async ({ params }: LoaderFunctionArgs<any>) => {
    const siteId = params.siteId ?? '';

    await dispatch(getContentAccess({ siteId }));

    return null;
  };

  // Loader that includes checking if the event has changed and dispatches setSelectedEvent if necessary
  const loadEventAndSetIfChanged = async ({ params }: LoaderFunctionArgs<any>) => {
    const eventId = params.eventId ?? '';
    setEventIfChanged(eventId);
    return null;
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path='/admin/:clientId/:siteId/monitor' loader={getContentTokenLoader} element={<Bootstrap />}>
        <Route index element={<Navigate to='events' />} />
        <Route path='events' element={<Outlet />}>
          <Route
            index
            element={<Review />}
            loader={async ({ params }) => {
              dispatch(getNextEvents({ siteId: params.siteId ?? '', eventType: EventType.REVIEW }));
              dispatch(getEventMetadata({ siteId: params.siteId ?? '' }));
              return 0;
            }}
          />
          <Route path=':eventId' element={<Outlet />}>
            <Route path='resolve' element={<ResolveEvent />} />
            <Route index element={<Clips />} loader={loadEventAndSetIfChanged} />
            <Route path='preview' element={<SelectedClips />} />
            <Route path='tag' element={<TagClips />} loader={dispatchActions(getEventMetadata)} />
          </Route>
        </Route>
        <Route path='archive' element={<Outlet />}>
          <Route
            index
            element={<Archive />}
            loader={async ({ params }) => {
              dispatch(getNextEvents({ siteId: params.siteId ?? '', eventType: EventType.ARCHIVE }));
              dispatch(getEventMetadata({ siteId: params.siteId ?? '' }));
              return 0;
            }}
          />
          <Route path=':eventId' element={<TagClips />} loader={dispatchActions(getEventMetadata, setSelectedEvent)} />
        </Route>
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
};

// Utility function to set the event only if it has changed.
//Do not set the event if it has not changed, so we do not lose the context of the selected clips for the current event
const setEventIfChanged = (eventId: string) => {
  const currentEventId = store.getState().selectedEvent.data.eventId;

  // Only dispatch setSelectedEvent if the eventId has changed
  if (currentEventId !== eventId) {
    store.dispatch(setSelectedEvent({ eventId }));
  }
};

export default AppRouter;
