import { useParams } from 'react-router-dom';
import { $, useAppSelector } from '@/common';
import { selectEvent } from '@/stores/events';
import { selectSelectedVideo } from '@/stores/selectedEvent';
import { EventTypesNavigation } from '@/modules/clips/components/navigation';
import Playback from '@/modules/clips/components/playback';
import { useMemo } from 'react';
import TagForm from '@/modules/clips/tagClips/tagForm';
import Video from '@/modules/clips/components/playback/Video';
import styled from 'styled-components';

const VerticalScrollBarWrapper = styled.div`
  div {
    overflow-y: auto;
  }

  div::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  div::-webkit-scrollbar:vertical {
    width: 11px;
  }

  div::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  div::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const TagClips = () => {
  const { siteId, eventId } = useParams();
  const event = useAppSelector(selectEvent(eventId));
  const selectedVideos = useAppSelector(selectSelectedVideo);
  const videosToRender = event?.archivedVideos ?? selectedVideos;
  const cameraIds = useMemo(() => {
    return videosToRender.map((obj: { cameraId: any }) => obj.cameraId).join(', ');
  }, [videosToRender]);

  return (
    <>
      <div className={$('flex items-center justify-between')}>
        <span className={$('flex gap-x-4 text-emphasis-secondary')}>
          <EventTypesNavigation>
            <span className={$('text-emphasis-primary')}>{event?.eventType}</span>
          </EventTypesNavigation>
          <span>/</span> <span>{cameraIds}</span>
        </span>
      </div>
      <div className={$('pt-5 grid grid-cols-1 gap-x-10 gap-y-8 lg:grid-cols-2 lg:gap-x-20 5xl:grid-cols-8 6xl:grid-cols-9 7xl:grid-cols-10 8xl:grid-cols-12')}>
        <div className={$('col-span-1 5xl:col-span-5 6xl:col-span-6 7xl:col-span-7 8xl:col-span-9')}>
          <VerticalScrollBarWrapper>
            <Playback style={{ maxHeight: `calc(-200px + 100vh)` }} className={$('grid grid-cols-1 gap-y-2 h-auto')} disablePlaytrack>
              {videosToRender.map((video) => {
                return (
                  <Video
                    key={video.bucketObjectId}
                    src={`${process.env.REACT_APP_PUBLIC_VIDEO_HOST}/${siteId}/raw/${video.bucketObjectId}`}
                    className={$('rounded w-full')}
                    controls
                  />
                );
              })}
            </Playback>
          </VerticalScrollBarWrapper>
        </div>
        <div className={$('col-span-1 5xl:col-span-3')}>
          <TagForm selectedVideo={videosToRender} />
        </div>
      </div>
    </>
  );
};

export default TagClips;
