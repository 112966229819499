export enum EventType {
  REVIEW = 'REVIEW',
  ARCHIVE = 'ARCHIVE',
}

export interface Video {
  cameraId: string;
  bucketObjectId: string;
}

export interface Event {
  eventId: string;
  siteId: string;
  startTime: string;
  endTime: string;
  thresholdEventTimestamp?: string;
  createdAt: string;
  updatedAt: string;
  expirationTime: string;
  eventType: string;
  reviewed: boolean;
  videos: Video[];
  archivedVideos: Video[];
  userNotes: string;
  tags: Tag[];
}

export interface TagClipFormModel {
  eventId: string;
  siteId: string;
  reviewed: boolean;
  tagNames: string[];
  newTagNames: string;
  archivedVideos: Video[];
  userNotes: string;
}

export interface Tag {
  tagName: string;
  count: number;
  lastUsed: string;
}

export interface EventTypeFreetext {
  type: string;
}

export interface EventMetadata {
  siteId: string;
  eventTypes: EventTypeFreetext[];
  eventTags: Tag[];
}
