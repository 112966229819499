import { useParams } from 'react-router-dom';
import { $, useAppSelector } from '@/common';
import { selectEvent } from '@/stores/events';
import { useMemo } from 'react';
import { selectSelectedVideo } from '@/stores/selectedEvent';
import { EventTypesNavigation, TagEventNavigation } from '@/modules/clips/components/navigation';
import Playback from '@/modules/clips/components/playback';
import Video from '@/modules/clips/components/playback/Video';
import uniqBy from 'lodash/uniqBy';
import trim from 'lodash/trim';

const SelectedClips = (): JSX.Element => {
  const { siteId, eventId } = useParams();
  const event = useAppSelector(selectEvent(eventId));
  const selectedVideo = useAppSelector(selectSelectedVideo);
  const cameraIds = useMemo(() => {
    return uniqBy(
      selectedVideo.map((obj: { cameraId: any }) => obj.cameraId),
      trim,
    ).join(', ');
  }, [selectedVideo]);

  const gridCols = useMemo(() => {
    if (selectedVideo.length <= 3) {
      return `grid-cols-${selectedVideo.length}`;
    }

    if (selectedVideo.length === 4) {
      return 'grid-cols-2';
    }

    return 'grid-cols-3';
  }, [selectedVideo]);

  const isSingleClipPlayback = selectedVideo.length === 1;

  return (
    <>
      <div className={$('flex items-center justify-between')}>
        <span className={$('flex gap-x-4 text-emphasis-secondary')}>
          <EventTypesNavigation>
            <span className={$('text-emphasis-primary')}>{event?.eventType}</span>
          </EventTypesNavigation>
          <span>/</span> <span>{cameraIds}</span>
        </span>
        <div className={$('flex flex-1 justify-end gap-x-12')}>
          <TagEventNavigation to='../tag' disabled={selectedVideo.length === 0} />
        </div>
      </div>
      <div className={$('pt-5')}>
        <Playback style={{ maxHeight: `calc(-200px + 100vh)` }} className={$('grid gap-y-4 gap-x-8 h-auto overflow-scroll', gridCols)} disablePlaytrack={isSingleClipPlayback}>
          {selectedVideo.map((video) => {
            return (
              <Video
                key={video.bucketObjectId}
                src={`${process.env.REACT_APP_PUBLIC_VIDEO_HOST}/${siteId}/raw/${video.bucketObjectId}`}
                className={$('rounded w-full')}
                controls = {isSingleClipPlayback}
              />
            );
          })}
        </Playback>
      </div>
    </>
  );
};

export default SelectedClips;
