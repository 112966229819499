import { $ } from '@/common';
import React from 'react';
import { type NavLinkProps, useNavigate } from 'react-router-dom';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const ContainedButton = (props: ButtonProps): JSX.Element => {
  const { className, children, ...rest } = props;

  return (
    <button
      className={$(
        'rounded-md w-20 px-3 py-2 text-emphasis-overlay font-normal bg-button-primary',
        'duration-[400ms] hover:bg-button-primary-hover hover:shadow-[0_4px_6px_1px_rgba(0,0,0,0.3)]',
        'disabled:cursor-not-allowed disabled:hover:shadow-none disabled:bg-button-primary-disabled disabled:text-emphasis-disabled disabled:ring-1 disabled:ring-button-primary-disabled-ring',
        className,
      )}
      {...rest}
    >
      <span className={$('flex gap-x-1 items-center text-center justify-center')}>{children}</span>
    </button>
  );
};

export const TextButton = (props: ButtonProps): JSX.Element => {
  const { className, children, ...rest } = props;

  return (
    <button
      className={$(
        'text-base font-normal text-emphasis-primary leading-6',
        'hover:underline',
        'disabled:cursor-not-allowed disabled:text-emphasis-disabled disabled:hover:no-underline',
        className,
      )}
      {...rest}
    >
      <span className={$('flex gap-x-1 items-center text-center justify-center')}>{children}</span>
    </button>
  );
};

export const IconTextButton = (props: ButtonProps): JSX.Element => {
  const { className, children, ...rest } = props;

  return (
    <button
      className={$(
        'text-base font-normal text-emphasis-secondary leading-6',
        'hover:text-emphasis-primary hover:underline',
        'disabled:cursor-not-allowed disabled:text-emphasis-disabled disabled:hover:no-underline',
        className,
      )}
      {...rest}
    >
      <span className={$('flex gap-x-1 items-center text-center justify-center')}>{children}</span>
    </button>
  );
};

export type NavButtonProps = NavLinkProps & ButtonProps & { component: (props: ButtonProps) => JSX.Element };

export const NavButton = (props: NavButtonProps): JSX.Element => {
  const { children, component: Button, ...rest } = props;
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => {
        navigate(props.to, { state: { ...props.state } });
      }}
      {...rest}
    >
      {children as React.ReactNode}
    </Button>
  );
};
