import { $ } from '@/common';
import React from 'react';

interface BadgeProps extends React.HTMLAttributes<HTMLSpanElement> {}

const Badge = (props: BadgeProps): JSX.Element => {
  const { children, className, ...rest } = props;

  return (
    <span
      className={$(
        'inline-flex items-center text-center justify-center rounded-full px-4 py-1.5 text-[#666666] text-base font-normal ring-1 ring-inset ring-button-primary-disabled-ring',
        className,
      )}
      {...rest}
    >
      {children}
    </span>
  );
};

export default Badge;
