import { $, useAppSelector } from '@/common';
import { getNextEvents, selectEvents, selectHasMoreEvents, selectIsEventsLoading } from '@/stores/events';
import { NavLink, useParams } from 'react-router-dom';
import { store } from '@/stores';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { EventType } from '@/stores/types';
import InfiniteScroll from '@/modules/events/components/infiniteScroll';

const Events = (props: { eventType: EventType; placeholder: string }): JSX.Element => {
  const { eventType, placeholder } = props;
  const { siteId } = useParams();
  const events = useAppSelector(selectEvents(eventType));
  const isLoading = useAppSelector(selectIsEventsLoading);
  const isHasMoreEvents = useAppSelector(selectHasMoreEvents(eventType));

  return (
    <>
      {/* 142px = page header + filter buttons + padding/margin */}
      <div style={{ height: `calc(-142px + 100vh)` }} className={$('overflow-y-auto')}>
        <InfiniteScroll isLoading={isLoading} hasMore={isHasMoreEvents} next={() => store.dispatch(getNextEvents({ siteId: siteId ?? '', eventType }))}>
          {!isLoading && events.length === 0 ? (
            <div className={$('grid min-h-full place-items-center text-emphasis-secondary')}>
              <span>{placeholder}</span>
            </div>
          ) : (
            <ul role='list' className={$('grid grid-cols-1 gap-y-2')}>
              {events.map((event, index) => (
                <li
                  key={index}
                  className={$(
                    'relative flex items-center justify-between gap-x-6 px-[10px] h-10 rounded-md border border-divider-muted bg-white text-emphasis-secondary',
                    'hover:bg-item-selected hover:text-white hover:border-transparent',
                  )}
                >
                  <NavLink to={event.eventId} className='cursor-pointer'>
                    <div className={$('flex min-w-0 gap-x-4')}>
                      <div className={$('grid grid-cols-2 gap-x-48 place-items-center text-base font-medium')}>
                        <span className={$('leading-6 min-w-80 align-baseline m-auto')}>{event.eventType}</span>
                        <span className={$('align-baseline m-auto')}>
                          <span>{format(parseISO(event.thresholdEventTimestamp || event.startTime), 'dd-MM-yy')}</span>{' '}
                          <span className={$('pl-4')}>{format(parseISO(event.thresholdEventTimestamp || event.startTime), 'HH:mm:ss')}</span>
                        </span>
                      </div>
                    </div>
                  </NavLink>
                  <div className={$('flex shrink-0 items-center gap-x-4')}>{/* <span>Delete</span> */}</div>
                </li>
              ))}
            </ul>
          )}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default Events;
