// https://tailwindui.com/components/application-ui/navigation/navbars

import { $ } from '@/common';
import { Disclosure } from '@headlessui/react';
import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

interface NavigationItemProps {
  label: string;
  name: string;
  to: string;
}

interface NavigationProps {
  children: JSX.Element | JSX.Element[];
}

export const NavigationItem = (_: NavigationItemProps): JSX.Element => <></>;

export const Navigation = ({ children }: NavigationProps): JSX.Element => {
  const items = useMemo(() => {
    if (Array.isArray(children)) {
      return children;
    }
    return [children];
  }, [children]);

  const getMenuClassName = (isActive: boolean): string =>
    $(
      isActive
        ? 'border-outline-selected text-emphasis-tab-selected'
        : 'border-transparent text-emphasis-tab-base hover:border-outline-hover hover:text-emphasis-tab-hover',
      'inline-flex items-center border-b-2 px-1 pt-1 font-normal text-base cursor-pointer h-full',
    );

  return (
    <Disclosure as='nav'>
      <div className={$('max-w-7xl px-8')}>
        <div className={$('flex justify-between h-16')}>
          <ul className={$('ml-6 flex space-x-8')}>
            {items.map((item) => {
              const { label, name, to } = item.props;
              return (
                <li key={name}>
                  <NavLink className={({ isActive }): string => getMenuClassName(isActive)} to={to}>
                    {label}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Disclosure>
  );
};
