import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createCustomLogger } from '@fingermarkglobal/eyecue-app-common/logging';
import { styleTags } from './common/style-loader';
import '@/common/assets/style/index.module.css';
import AppRouter from './routing';
import { store } from '@/stores';
import { Provider as DataProvider } from 'react-redux';
import { JitsuProvider } from '@jitsu/jitsu-react';

const jitsuHost = process.env.JITSU_HOST || '';

declare global {
  interface Window {
    logger: any;
    renderMonitor: (containerId: string) => void;
    unmountMonitor: (containerId: string) => void;
  }
}

// Global logger
window.logger = createCustomLogger({ label: 'eyecue-monitor' });

let reactDOMRoot: ReactDOM.Root | undefined;

// Global method to mount dashboard as micro front-end into shawdow DOM
window.renderMonitor = (containerId: string): void => {
  document.head.append(...styleTags);
  const el = document.getElementById(containerId) as HTMLElement;
  reactDOMRoot = ReactDOM.createRoot(el);

  window.logger.info(`renderMonitor ${containerId}`);
  reactDOMRoot.render(
    <JitsuProvider options={{ host: jitsuHost }}>
      <DataProvider store={store}>
        <AppRouter />
      </DataProvider>
    </JitsuProvider>,
  );
};

window.unmountMonitor = (containerId: string): void => {
  window.logger.info(`unmountMonitor ${containerId}`);
  reactDOMRoot?.unmount();
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
