import { isEmpty } from '@/common';
import createHttp from '@/services/http';
import { REQUEST_STATUS, type State } from '@/types';
import { type ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { type RootState } from '.';

export interface SiteConfig {
  clientId: string;
  siteId: string;
  details: {
    name: string;
    timezone: string;
  };
}

export interface SiteConfigState extends State<{ siteConfig?: SiteConfig }> {}

const initialState: SiteConfigState = {
  data: { siteConfig: undefined },
  requestStatus: REQUEST_STATUS.IDLE,
  error: null,
};

const getSiteConfigQuery = `
  query getSiteConfig($siteIds: [String], $clientId: String) {
    getStoresV2(siteIds: $siteIds, clientId: $clientId) {
      clientId,
      siteId,
      details {
        name,
        timezone
      }
    }
  }`;

export const getSiteConfig = createAsyncThunk<SiteConfig | undefined, { siteId: string; clientId: string }>(
  'stores/getConfig',
  async ({ siteId, clientId }): Promise<SiteConfig | undefined> => {
    const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
      query: getSiteConfigQuery,
      variables: {
        siteIds: [siteId],
        clientId,
      },
    });

    const siteData = get(response, 'data.data.getStoresV2', []).find((site: { siteId: string }) => site.siteId === siteId);

    if (isEmpty(siteData)) {
      window.logger.error(`No site config found.`);
      return undefined;
    }

    return siteData as SiteConfig;
  },
);

// Grant access for user to view site data
export const getContentAccess = createAsyncThunk<boolean, { siteId: string }>('stores/getContentAccess', async ({ siteId }): Promise<boolean> => {
  const response = await createHttp(process.env.REACT_APP_CATCH_API_URL, true, {
    withCredentials: true,
  }).get(`/get-content-access/${siteId}`);
  return get(response, 'data', false);
});

export const siteConfigSlice = createSlice({
  name: 'siteConfigs',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<SiteConfigState>) => {
    builder.addCase(getSiteConfig.pending, (state) => {
      state.requestStatus = REQUEST_STATUS.LOADING;
    });

    builder.addCase(getSiteConfig.fulfilled, (state, action) => {
      state.requestStatus = REQUEST_STATUS.SUCCEEDED;
      state.data.siteConfig = action.payload;
    });

    builder.addCase(getSiteConfig.rejected, (state, action) => {
      state.requestStatus = REQUEST_STATUS.FAILED;
      state.error = action.error;
    });
  },
});

export default siteConfigSlice.reducer;

export const selectSiteConfig = (state: RootState): SiteConfig | undefined => state.siteConfigs.data.siteConfig;
