import { LeftArrowIcon, PlayCircleIcon, TagIcon } from '@/common/assets/icons';
import { $ } from '@/common';
import { IconTextButton, NavButton, type NavButtonProps } from '@/modules/components/buttons';

export const ViewSelectedNavigation = (props: Omit<NavButtonProps, 'component' | 'to' | 'children'>): JSX.Element => {
  const { disabled, ...rest } = props;

  return (
    <NavButton component={IconTextButton} to='preview' disabled={disabled} {...rest}>
      <PlayCircleIcon className={$({ 'fill-emphasis-disabled': disabled })} />
      <span className={$('text-sm')}>Preview selected</span>
    </NavButton>
  );
};

export const TagEventNavigation = (props: Omit<NavButtonProps, 'component' | 'children'>): JSX.Element => {
  const { disabled, ...rest } = props;

  return (
    <NavButton component={IconTextButton} disabled={disabled} {...rest}>
      <TagIcon className={$({ 'fill-emphasis-disabled': disabled })} />
      <span className={$('text-sm')}>Tag event</span>
    </NavButton>
  );
};

export const EventTypesNavigation = (props: Omit<NavButtonProps, 'component' | 'to'>): JSX.Element => {
  const { children, ...rest } = props;

  return (
    <span className={$('flex items-center font-normal text-lg')}>
      <NavButton component={IconTextButton} to='..' {...rest}>
        <LeftArrowIcon className={$('fill-emphasis-primary')} />
        <span className={$('ml-3')}>{children}</span>
      </NavButton>
    </span>
  );
};
