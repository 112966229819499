import Spinner from '../../../components/spinner';
import { useEffect, useRef } from 'react';

export interface ContentPanelProps {
  children: JSX.Element | JSX.Element[];
  next?: () => void;
  hasMore?: boolean;
  isLoading?: boolean;
}

const InfiniteScroll = (props: ContentPanelProps): JSX.Element => {
  const { children, isLoading = false, next, hasMore = false } = props;
  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore) {
        next?.();
      }
    });

    if (observerTarget.current !== null) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current !== null) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, hasMore, next]);

  return (
    <>
      {children}
      <div ref={observerTarget}></div>
      <Spinner hide={!isLoading} />
    </>
  );
};

export default InfiniteScroll;
