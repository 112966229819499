import React, { useEffect, useState } from 'react';
import { SpinnerIcon } from '@/common/assets/icons';
import { $ } from '@/common';
import axios from 'axios';

interface VideoProps extends React.VideoHTMLAttributes<HTMLVideoElement> {}

const Video = (props: VideoProps): JSX.Element => {
  const { src, ...rest } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [video, setVideo] = useState('');

  // Safari sometimes omits cookies from the request header when performing a GET request for video, leading to data loss.
  // To ensure cookies are included, use 'axios' with the 'withCredentials' option.
  useEffect(() => {
    void axios
      .get(src ?? '', { responseType: 'blob', withCredentials: true })
      .then((response) => {
        if (response.status === 200) {
          const video = URL.createObjectURL(new Blob([response.data], { type: 'video/mp4' }));
          setVideo(video);
        }
      })
      .catch((error) => {
        window.logger.error(error);
      });
  }, [src]);

  return (
    <div className={$('relative flex items-center justify-center')}>
      {isLoading && (
        <div className={$('absolute flex items-center justify-center inset-0 bg-indicator-skeletal')}>
          <SpinnerIcon />
        </div>
      )}
      <video
        {...rest}
        onLoadStart={() => {
          setIsLoading(true);
        }}
        onLoadedData={() => {
          setIsLoading(false);
        }}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        controlsList='nodownload noremoteplayback noplaybackrate'
        disablePictureInPicture
        src={video}
      />
    </div>
  );
};

export default Video;
