import { $ } from '@/common';

export const PlayIcon = (props: any): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' {...props}>
    <path
      fillRule='evenodd'
      d='M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z'
      clipRule='evenodd'
    />
  </svg>
);

export const PauseIcon = (props: any): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' {...props}>
    <path
      fillRule='evenodd'
      d='M6.75 5.25a.75.75 0 0 1 .75-.75H9a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75V5.25Zm7.5 0A.75.75 0 0 1 15 4.5h1.5a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H15a.75.75 0 0 1-.75-.75V5.25Z'
      clipRule='evenodd'
    />
  </svg>
);

export const LeftArrowIcon = (props: any): JSX.Element => (
  <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='mask0_266_37923' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='3' y='4' width='14' height='14'>
      <path
        d='M15.6594 10.1391H6.35107L10.4177 6.07246C10.7427 5.74746 10.7427 5.21412 10.4177 4.88912C10.262 4.73308 10.0507 4.64539 9.83024 4.64539C9.60981 4.64539 9.39843 4.73308 9.24274 4.88912L3.75107 10.3808C3.42607 10.7058 3.42607 11.2308 3.75107 11.5558L9.24274 17.0475C9.56774 17.3725 10.0927 17.3725 10.4177 17.0475C10.7427 16.7225 10.7427 16.1975 10.4177 15.8725L6.35107 11.8058H15.6594C16.1177 11.8058 16.4927 11.4308 16.4927 10.9725C16.4927 10.5141 16.1177 10.1391 15.6594 10.1391Z'
        fill='black'
      />
    </mask>
    <g mask='url(#mask0_266_37923)'>
      <rect y='0.968262' width='20' height='20' fill='#00B780' {...props} />
    </g>
  </svg>
);

export const CheckCircleIcon = (props: any): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-6 h-6' {...props}>
    <path strokeLinecap='round' strokeLinejoin='round' d='M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z' />
  </svg>
);

export const PlayCircleIcon = (props: any): JSX.Element => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='mask0_3561_2614' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='2' y='2' width='20' height='20'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM15.47 12.4L10.8 15.9C10.47 16.15 10 15.91 10 15.5V8.5C10 8.09 10.47 7.85 10.8 8.1L15.47 11.6C15.74 11.8 15.74 12.2 15.47 12.4Z'
        fill='black'
      />
    </mask>
    <g mask='url(#mask0_3561_2614)'>
      <rect width='24' height='24' fill='#00B780' {...props} />
    </g>
  </svg>
);

export const TagIcon = (props: any): JSX.Element => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='mask0_3561_2904' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='2' y='2' width='20' height='20'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.41 2.58L21.41 11.58C21.77 11.94 22 12.45 22 13C22 13.55 21.78 14.05 21.41 14.41L14.41 21.41C14.05 21.78 13.55 22 13 22C12.45 22 11.95 21.78 11.59 21.42L2.59 12.42C2.22 12.05 2 11.55 2 11V4C2 2.9 2.9 2 4 2H11C11.55 2 12.05 2.22 12.41 2.58ZM4 5.5C4 6.33 4.67 7 5.5 7C6.33 7 7 6.33 7 5.5C7 4.67 6.33 4 5.5 4C4.67 4 4 4.67 4 5.5Z'
        fill='black'
      />
    </mask>
    <g mask='url(#mask0_3561_2904)'>
      <rect width='24' height='24' fill='#00B780' {...props} />
    </g>
  </svg>
);

export const SpinnerIcon = (props: any): JSX.Element => (
  <svg aria-hidden='true' className={$('w-8 h-8 text-gray-200 animate-spin fill-indicator-okay', props.className)} viewBox='0 0 100 100' fill='none'>
    <path
      d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
      fill='currentColor'
    />
    <path
      d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
      fill='currentFill'
    />
  </svg>
);

export const SearchIcon = (props: any): JSX.Element => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='state=Default'>
      <mask id='mask0_347_68594' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='2' y='2' width='16' height='16'>
        <g id='Icon Mask'>
          <path
            id='Round'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.6556 12.0596H13.3139L16.8472 15.6096C17.1889 15.9513 17.1889 16.5096 16.8472 16.8513C16.5056 17.193 15.9472 17.193 15.6056 16.8513L12.0639 13.3096V12.6513L11.8389 12.418C10.6722 13.418 9.08058 13.9346 7.38892 13.6513C5.07225 13.2596 3.22225 11.3263 2.93892 8.99298C2.50558 5.46798 5.47225 2.50131 8.99725 2.93464C11.3306 3.21798 13.2639 5.06798 13.6556 7.38464C13.9389 9.07631 13.4222 10.668 12.4222 11.8346L12.6556 12.0596ZM4.56392 8.30964C4.56392 10.3846 6.23892 12.0596 8.31392 12.0596C10.3889 12.0596 12.0639 10.3846 12.0639 8.30964C12.0639 6.23464 10.3889 4.55964 8.31392 4.55964C6.23892 4.55964 4.56392 6.23464 4.56392 8.30964Z'
            fill='black'
          />
        </g>
      </mask>
      <g mask='url(#mask0_347_68594)'>
        <rect id='Color Fill' width='20' height='20' fill='#858C94' {...props} />
      </g>
    </g>
  </svg>
);
